import React, { useState } from "react"
import Container from "../components/container"
import Seo from "../components/Seo"
import CustomHeader from "../components/customHeader"
import TimezonesConversionView from "../components/TimezonesConversionView"
import TimezonesTableView from "../components/TimezonesTableView"
import SideBarView from "../components/SideBarView"
import { Typography, Grid } from "@mui/material"
import { Link as GatsbyLink } from "gatsby"
import { styled } from "@mui/system"

const Link = styled(props => <GatsbyLink {...props} />)({
  color: "#69c",
  textDecoration: "none",
  "&:hover": { color: "#8fc9ff" }
})

const TimezonesConverter = () => {
  const [timestamp, updateTimestamp] = useState(0)

  return (
    <Container>
      <CustomHeader title={`Unix Time Conversion With Timezones / List Of Timezones`}/>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={9} xl={9}>
          <Grid container>
            <p>A time zone is an area that represents a uniform standard time for legal, commercial and social purposes.
              This page allows you to compare time between multiple time zones. All timezone timestamps are calculated
              based on a time difference from the <Link target={`blank`}
                                                        to={`https://en.wikipedia.org/wiki/Coordinated_Universal_Time`}>UTC</Link>.
            </p>
            <TimezonesConversionView showTimezonesTable={(timestamp) => {
              updateTimestamp(timestamp)
            }}/>
          </Grid>
          <Grid container>
            {timestamp ? (
              <div>
                <Typography variant={`h2`}
                            style={{
                              // color: "#4d3267",
                              marginTop: "20px",
                              fontSize: "24px",
                              fontWeight: "bold",
                              fontFamily: "Karla, sans-serif"
                            }}>List of All Timezones with converted dates </Typography>
                <p>The table below will give all the time zones with converted dates. You can also filter with the
                  multiple regions, sort and compare the <Link target={`blank`} to={`/date-difference-calculator/`}>time
                    difference</Link> between them.</p>
                <TimezonesTableView timestamp={Number(timestamp)}/>
              </div>
            ) : null}
          </Grid>
          <Typography variant={`h5`}>More About Timestamp Timezone Conversions</Typography>
          <Typography variant={`p`}>
            Timestamp timezone conversion is the process of converting a timestamp from one time zone to another. This
            is an important aspect of working with timestamps in computer systems because timestamps are often used to
            represent a specific point in time and are affected by the time zone in which they were created.
            <br/><br/>
            There are a few different ways to convert timestamps between time zones, depending on the format of the
            timestamp and the programming language or tools you are using. One common method is to use the built-in
            functions in programming languages such as Python, JavaScript, and C.
            <br/><br/>
            For example, in Python, you can use the pytz library to convert a timestamp from one time zone to another.
            This library provides a timezone object that can be used to convert a timestamp to a different time zone.
            <br/><br/>
            Another popular method is to use a library like moment.js for JavaScript. This library provides methods for
            converting timestamps between time zones, as well as for formatting and parsing timestamps.
            <br/><br/>
            It's also possible to use online converters, that can convert a timestamp from one time zone to another, by
            providing the timestamp, the timezone it was created and the target timezone.
            <br/><br/>
            When working with timestamps, it's important to remember that time zones can change due to daylight saving
            time. This means that some timestamps may have different representations depending on the time of year, this
            is why it's important to use libraries that take into account the current timezone rules.
            <br/><br/>
            In conclusion, timestamp timezone conversion is an important aspect of working with timestamps in computer
            systems. There are different methods and tools that can be used to convert timestamps between time zones,
            such as built-in functions in programming languages, libraries, and online converters.
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={3} xl={3}>
          <SideBarView/>
        </Grid>
      </Grid>
    </Container>
  )
}
export default TimezonesConverter

export const Head = () => {
  const siteMetadata = {
    description: "Conversion of time to different timezone and list of all available timezone will be given with the timezone filter",
    description2: "",
    keywordsContent: "timezone, time conversion, timezone converter, utc, timezone offset"
  }
  return (
    <Seo
      title="Unix Time Conversion With Timezones / List Of Timezones"
      canonicalUrl={"https://www.epochconverter.io/timezones-converter/"}
      siteMetadata={siteMetadata}
    />
  )
}
