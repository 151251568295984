/* eslint-disable react/no-unused-state */
import React, { useState } from "react"
import { Col, Row } from "antd"
import * as styles from "./PagesLayout.module.less"
import dayjs from "dayjs"
import localizedFormat from "dayjs/plugin/localizedFormat"
import { Button, MenuItem, OutlinedInput as Input, Select } from "@mui/material"
import { makeStyles } from "@mui/styles"

const { timezonesList } = require("../components/TimezonesList")

dayjs.extend(localizedFormat)

var utc = require("dayjs/plugin/utc")
var timezone = require("dayjs/plugin/timezone") // dependent on utc plugin
dayjs.extend(utc)
dayjs.extend(timezone)

const allTimezones = timezonesList

const CustomStyles = makeStyles({
  inputLayouts: {
    width: `100%`,
    margin: 0,
    height: `36px`,
    "&.Mui-focused fieldset": {
      borderColor: `#b7b7b7 !important`
    }
  },
  inputLayoutsSelect: {
    width: `100%`,
    height: `36px`,
    margin: 0,
    "&.Mui-focused fieldset": {
      borderColor: "#b7b7b7 !important"
    }
  }
})


const TimezonesConversionViewFn = ({ showTimezonesTable }) => {
  const classes = CustomStyles()
  const onTimestampChange = (e) => {
    let timestamp = e.target.value
    timestamp = timestamp
      .split("")
      .filter(n => !Number.isNaN(Number(n)))
      .join("")
    return timestamp
  }

  const getDateTimestampFromUnixAndTimezone = ({ timestampValue, timezone }) => {
    if (timestampValue && String(timestampValue).length > 14) {
      timestampValue /= 1000
    } else if (timestampValue && String(timestampValue).length <= 11) {
      timestampValue *= 1000
    }
    return dayjs(timestampValue)
      .tz(timezone)
      .format("dddd, MMM DD, YYYY hh:mm:ss.SSS A")
  }

  const getDiffInSecondsFromTimezone = (timezone) => {
    return dayjs().tz(timezone).utcOffset()
  }

  let { timestamp, timezone } = {}

  let [showResult, updateShowResult] = useState(false)

  if (timestamp && timezone && !showResult) {
    updateShowResult(true)
  }

  if (!timestamp) {
    timestamp = dayjs().valueOf()
  }

  if (!timezone) {
    ({ timeZone: timezone } = new Intl.DateTimeFormat().resolvedOptions())
  }

  const [tempTimestamp, setTempTimestamp] = useState(timestamp)
  const [tempTimezone, setTempTimezone] = useState(timezone)
  const [finalDataStr, updateFinalData] = useState("{}")
  const finalData = JSON.parse(finalDataStr)
  if (showResult && (!finalData.timestamp || !finalData.timezone)) {
    updateFinalData(JSON.stringify({ timestamp: tempTimestamp, timezone: tempTimezone }))
  }

  return (
    <div>
      <Row gutter={16} style={{ marginTop: "50px" }}>
        <Col xs={24} sm={8} md={8} xl={6}>
          <Input
            className={classes.inputLayouts}
            placeholder={"Enter timestamp"}
            value={tempTimestamp}
            onChange={(event) => {
              const tempTimestampInput = onTimestampChange(event)
              setTempTimestamp(tempTimestampInput)
            }}
          />
        </Col>
        <Col xs={24} sm={16} md={16} xl={10}>
          <Select
            className={classes.inputLayoutsSelect}
            showSearch
            placeholder="Select a timezone"
            optionFilterProp="children"
            onChange={e => {
              const { value } = e.target
              setTempTimezone(value)
            }}
            filterOption={(input, option) => (option.children.join("").toLowerCase().indexOf(input.toLowerCase()) >= 0)}
            value={tempTimezone}
          >
            {allTimezones.map(timezone => (
              <MenuItem key={timezone} value={timezone}>{timezone} (
                GMT {dayjs().tz(timezone).format("Z")} ) </MenuItem>))}
          </Select>
        </Col>
        <Col xs={24} sm={24} md={24} xl={8}>
          <Button
            className={styles.btnLayouts}
            type="primary"
            onClick={() => {
              // setQuery({ timestamp: tempTimestamp, timezone: tempTimezone }, "push")
              updateFinalData(JSON.stringify({ timestamp: tempTimestamp, timezone: tempTimezone }))
              updateShowResult(true)
            }}
          >
            Convert Timestamp
          </Button>
        </Col>
      </Row>
      {showResult && finalData.timestamp && finalData.timezone ? (<Row>
        <h2 style={{
          marginTop: "20px",
          color: "#4d3267",
          fontSize: "24px",
          fontWeight: "bold",
          fontFamily: "Karla, sans-serif"
        }}>Conversion Result for {finalData.timestamp} and {finalData.timezone} : </h2>
        <p>Given timestamp <b>{finalData.timestamp}</b> with timezone <b>{finalData.timezone}</b> conversion will
          be <b>{getDateTimestampFromUnixAndTimezone({
            timestampValue: finalData.timestamp,
            timezone: finalData.timezone
          })}</b>.
          <br/>Time difference from the UTC/GMT in seconds is <b>{getDiffInSecondsFromTimezone(finalData.timezone)}</b>
        </p>
      </Row>) : null}
      {showTimezonesTable(Number(finalData.timestamp) || Number(tempTimestamp)) && null}
    </div>
  )
}

export default TimezonesConversionViewFn
