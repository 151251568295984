import React, { useState } from "react"
import { Row, Col, Select } from "antd"
import { inputLayoutsHR } from "./PagesLayout.module.less"
import dayjs from "dayjs"
import { DataGrid } from "@mui/x-data-grid"
import { Box } from "@mui/material"

const { timezonesList } = require("../components/TimezonesList")

const { Option } = Select

const allTimezonesList = timezonesList

const TimezonesTableView = ({ timestamp }) => {

  const getDiffInSecondsFromTimezone = (timezone) => {
    return dayjs().tz(timezone).utcOffset()
  }

  const columns = [
    {
      headerName: "Regions",
      field: "timezone",
      key: "timezone",
      sorter: (a, b) => a.timezone > b.timezone ? 1 : -1,
      fixed: "left",
      flex: 1
    },
    {
      headerName: "Date Time",
      field: "dateTimestamp",
      key: "dateTimestamp",
      sorter: (a, b) => a.offsetValue - b.offsetValue,
      flex: 1.5
    },
    {
      headerName: "UTC/GMT format",
      field: "offsetFormatted",
      key: "timezone",
      sorter: (a, b) => a.offsetValue - b.offsetValue,
      flex: 1
    },
    {
      headerName: "Offset in seconds",
      field: "offsetValue",
      key: "timezone",
      sorter: (a, b) => a.offsetValue - b.offsetValue,
      flex: 1
    }
  ]

  const completeDataSource = []

  allTimezonesList.forEach((timezone) => {
    completeDataSource.push({
      timezone,
      dateTimestamp: dayjs.tz(timestamp, timezone).format("MMM DD, YYYY hh:mm:ss.SSS A"),
      offsetFormatted: `GMT ${dayjs().tz(timezone).format("Z")}`,
      offsetValue: getDiffInSecondsFromTimezone(timezone)
    })
  })

  const [selectedTimezones, updateSelectedTimezones] = useState([])
  const [dataSource, updateDataSource] = useState(completeDataSource)
  const firstTimezone = dataSource[0].timezone
  const updatedTimestamp = (completeDataSource.filter(item => item.timezone === firstTimezone))[0].dateTimestamp
  if (dataSource[0].dateTimestamp !== updatedTimestamp) {
    const tempDataSource = selectedTimezones && selectedTimezones.length ? completeDataSource.filter(item => selectedTimezones.includes(item.timezone)) : completeDataSource
    updateDataSource(tempDataSource)
  }

  return (
    <div>
      <Row>
        <Col xs={24} sm={24} md={16} xl={12}>
          <Select
            className={inputLayoutsHR}
            showSearch
            placeholder="Filter by regions"
            optionFilterProp="children"
            label="timezone"
            mode="multiple"
            aria-label={"timezone"}
            style={{
              marginLeft: 0,
              borderRadius: "3px",
              outline: 0,
              boxShadow: "none",
              marginBottom: "20px",
              width: "100%"
              // border: '1px solid #c7417b',
            }}
            onChange={values => {
              const tempDataSource = values && values.length ? completeDataSource.filter(item => values.includes(item.timezone)) : completeDataSource
              updateDataSource(tempDataSource)
              updateSelectedTimezones(values)
            }}
            filterOption={(input, option) => {
              return option.children.join("").toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            }
            value={selectedTimezones}
          >
            {allTimezonesList.map(timezone => (
              <Option key={timezone} value={timezone}>{timezone} ( GMT {dayjs().tz(timezone).format("Z")} ) </Option>))}
          </Select>
        </Col>
      </Row>
      <Box sx={{ height: 600, width: "100%" }}>
        <DataGrid rows={dataSource} columns={columns} getRowId={row=>row.timezone}
                  bordered size="small" pagination={{ position: ["topRight"] }} sticky/>
      </Box>
    </div>
  )
}

export default TimezonesTableView
